<template>
  <footer>
    <div class="footer">
      <img src="@/assets/photos/logo معيار النجاح .png " alt="" />
      <p>
        {{ footer.description }}
      </p>
      <div class="linkes">
        <router-link to="/en" class="nav-link active" aria-current="page"
          >Home</router-link
        >
        <router-link to="/en/about" class="nav-link" aria-current="page"
          >About</router-link
        >
        <router-link to="/en/contact-us" class="nav-link" aria-current="page"
          >Contact Us</router-link
        >
      </div>
      <div class="input">
        <i class="fa-solid fa-envelope"></i>
        <form>
          <input type="email" placeholder="Enter Your Email" />
          <button type="submit" class="Subscribe">Subscribe</button>
        </form>
      </div>
      <div class="soial">
        <a :href="linkes.facebook" target="_blank">
          <i class="fa-brands fa-facebook"></i>
        </a>
        <a :href="linkes.twitter" target="_blank"
          ><i class="fa-brands fa-x-twitter"></i
        ></a>
        <a :href="linkes.instagram" target="_blank">
          <i class="fa-brands fa-square-instagram"></i>
        </a>
        <a :href="linkes.linkedin" target="_blank">
          <i class="fa-brands fa-linkedin"></i>
        </a>
        <a :href="linkes.snapchat" target="_blank">
          <i class="fa-brands fa-snapchat"></i>
        </a>
        <a :href="linkes.tiktok" target="_blank">
          <i class="fa-brands fa-tiktok"></i>
        </a>
        <a :href="linkes.youtube" target="_blank">
          <i class="fa-brands fa-youtube"></i>
        </a>
      </div>
    </div>
    <div class="end-footer">
      <h4>All rights reserved @ Success Standard Training Company SSTC</h4>
      <h4>Made by <span>X</span> Coders coding with expert</h4>
    </div>
  </footer>
</template>
<script>
import footrData from "@/data/footer.json";
export default {
  name: "FooterApp",
  data() {
    return {
      footer: [],
      linkes: [],
      footrData,
    };
  },
  async mounted() {
    this.footer = this.footrData.data.footer;
    this.linkes = this.footrData.data.settings;
  },
};
</script>
