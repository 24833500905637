<template>
  <div class="about">
    <h4>
      <router-link to="/">Home</router-link>
      <i class="fa-solid fa-chevron-right"></i><span>About Us</span>
    </h4>
    <WhoWe :parntabout="parntabout" />
    <WeAimedVue
      :parntVission="parntVission"
      :paentGoals="paentGoals"
      :paentValues="paentValues"
      :paentMission="paentMission"
    />

    <div class="row px-5">
      <div class="col-12">
        <div class="about-us">
          <h3>Administrative structure</h3>
        </div>
        <div class="about-us w-100 d-flex justify-content-center">
          <img src="@/assets/photos/structure_en.jpeg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WhoWe from "@/components/Global/home/WhoWe.vue";
import WeAimedVue from "@/components/Global/About/WeAimed.vue";
// import axios from "axios";
import AboutData from "@/data/about.json";
export default {
  name: "AboutView",
  components: {
    WhoWe,
    WeAimedVue,
  },
  data() {
    return {
      parntabout: [],
      parntVission: [],
      paentGoals: [],
      paentValues: [],
      paentMission: [],
      AboutData,
    };
  },
  async mounted() {
    this.parntabout = AboutData.data.about_us;
    this.parntVission = AboutData.data.vission;
    this.paentMission = AboutData.data.mission;
    this.paentGoals = AboutData.data.goals;
    this.paentValues = AboutData.data.values;
  },
};
</script>

<style lang="scss" scoped>
.about {
  h4 {
    margin-top: 12rem;
  }
}
</style>
