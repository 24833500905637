<template>
  <div class="home_slider">
    <swiper
      :pagination="pagination"
      :direction="'vertical'"
      :slidesPerView="1"
      :spaceBetween="30"
      :mousewheel="true"
      :autoplay="{
        delay: 3000,
        disableOnInteraction: false,
      }"
      :modules="modules"
      class="mySwiper"
    >
      <swiper-slide v-for="(slide, i) in parntsliderData.media" :key="i">
        <img :src="slide" alt="" />
      </swiper-slide>
      <div class="overtext">
        <!-- <p class="title">
          <img src="@/assets/photos/binoculars 1.svg" alt="" />
          {{ parntsliderData.title }}
        </p> -->
        <h4 class="subtitle mb-0" v-if="$route.params.lang == 'ar'">
          مرحبًا بكم في شركة معيار النجاح لحلول التدريب والتعليم - SSTC<br />
        </h4>
        <h4 class="subtitle title mb-0" v-else>
          Welcome to Success Standard for Training and Educational Solutions
          Company
        </h4>
        <h4 class="subtitle my-0 fs-4 px-5" v-if="$route.params.lang == 'en'">
          SSTC – Your Gateway to Excellence in Education and Training in Saudi
          Arabia and across the Arab world.
        </h4>
        <h6 class="subtitle mt-0" v-if="$route.params.lang == 'ar'">
          في شركة معيار النجاح، نتخصص في تقديم حلول تدريبية وتعليمية مبتكرة
          مصممة خصيصًا لتلبية احتياجاتكم الشخصية واحتياجات مؤسستكم. <br />
          نعتمد على أحدث التقنيات والأساليب التعليمية، بما في ذلك التعلم
          التفاعلي المباشر والتدريب القائم على استراتيجية التدرب حتى الاتقان.
        </h6>
        <h6 class="subtitle mt-0 px-5" v-if="$route.params.lang == 'en'">
          At SSTC, we specialize in delivering cutting-edge training and
          educational solutions tailored to your organization's needs. <br />
          Our innovative approaches incorporate the latest technologies and
          methodologies
        </h6>
        <!-- <h4 class="subtitle">{{ parntsliderData.subtitle }}</h4> -->
        <h6 class="desc" v-if="$route.params.lang == 'ar'">
          {{ parntsliderData.description }}
        </h6>
        <h6 class="desc" v-else>
          Partner with us to Transform your Educational and Training
          Experiences, <br />
          Ensuring Success in An ever-evolving Digital Landscape.
        </h6>
        <!-- <a href="#OurTrainingSolutions">
          <button class="more">معرفة المزيد</button>
        </a> -->
      </div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";

import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";

export default {
  name: "HomeSlider",

  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
      pagination: {
        clickable: true,
      },
    };
  },
  data() {
    return {};
  },
  props: {
    parntsliderData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.mySwiper {
  @media screen and (max-width: 600px) {
    height: 80vh !important;
    margin-bottom: 8rem;
  }
}
</style>
