<template>
  <div>
    <HomeAr v-if="$route.params.lang == 'ar'" />
    <HomeEn v-else />
  </div>
</template>

<script>
import HomeAr from "@/views/Home/HomeView.vue";
import HomeEn from "@/views/Home/HomeViewEn.vue";
export default {
  name: "HomeView",
  components: {
    HomeAr,
    HomeEn,
  },


  watch: {
    "$route.params.lang": {
      immediate: true,
      handler(lang) {
        // Update the <html> lang attribute
        document.querySelector("html").setAttribute("lang", lang);

        // If route's lang parameter doesn't match the current lang, update the route
        if (lang !== this.$route.params.lang) {
          this.$router.push({ params: { lang } });
        }
      },
    },
  },
};
</script>
