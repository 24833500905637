<template>
  <div class="contact-us">
    <h4>
      <router-link to="/">Home</router-link>
      <i class="fa-solid fa-chevron-right"></i><span>Contact Us</span>
    </h4>
    <ContactUsComop
      :parentContact="parentContact"
      :parentSitting="parentSitting"
    />
  </div>
</template>
<script>
import ContactUsComop from "../../components/Global/ContactUS/ContactUsComop.vue";
import contactUsData from "@/data/contactUs.json";
export default {
  name: "ContactUs",
  components: {
    ContactUsComop,
  },
  data() {
    return {
      parentContact: [],
      parentSitting: [],
      contactUsData,
    };
  },
  async mounted() {
    this.parentContact = contactUsData.data.contact_us;
    this.parentSitting = contactUsData.data.settings;
  },
};
</script>
<style lang="scss" scoped>
.contact-us {
  h4 {
    margin-top: 12rem;
  }
}

@media screen and (max-width: 600px) {
  .contact-us {
    h4 {
      margin-top: 13rem;
    }
  }
}
</style>
