<template>
  <div class="topnav">
    <div class="right-sec">
      <div>
        <a id="whatsapp_float" class="whatsapp_float" :href="`https://wa.me/${parentSitting.whatsapp}`" target="_blank"><i
          class="fa-brands fa-whatsapp"></i></a>
      </div>
      <h4>
        <a :href="`mailto:${parentSitting.email}`" target="_blank">
          <i class="fa-solid fa-envelope"></i>{{ parentSitting.email }}
          <span>|</span>
        </a>
      </h4>

      <h4 v-if="parentSitting.location">
        <a
          :href="parentSitting.address"
          v-if="$route.params.lang == 'ar'"
          target="_blank"
        >
          <i class="fa-solid fa-location-dot"></i>
          {{ parentSitting.location }}<span>|</span>
        </a>
        <a :href="parentSitting.address" v-else target="_blank">
          <i class="fa-solid fa-location-dot"></i>
          Kingdom of Saudi Arabia <span>|</span>
        </a>
      </h4>

      <h4>
        <a :href="`tel:${parentSitting.phone}`" target="_blank">
          <i class="fa-solid fa-phone phone-icon-nav"></i
          >{{ parentSitting.phone }}
        </a>
      </h4>
      <h4>
        <span>|</span>
        <a :href="`https://wa.me/${parentSitting.whatsapp}`" target="_blank">
          <i class="fa-brands fa-whatsapp"></i>{{ parentSitting.whatsapp }}
        </a>
      </h4>
    </div>
    <div class="left-sec">
      <a :href="parentSitting.facebook" target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a :href="parentSitting.youtube" target="_blank">
        <i class="fa-brands fa-youtube"></i>
      </a>
      <a :href="parentSitting.linkedin" target="_blank">
        <i class="fa-brands fa-linkedin-in"></i>
      </a>
      <a :href="parentSitting.twitter" target="_blank">
        <i class="fa-brands fa-x-twitter"></i>
      </a>
      <a :href="parentSitting.snapchat" target="_blank">
        <i class="fa-brands fa-snapchat"></i>
      </a>
      <a :href="parentSitting.instagram" target="_blank">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a :href="parentSitting.tiktok" target="_blank">
        <i class="fa-brands fa-tiktok"></i>
      </a>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TopNav",
  data() {
    return {
      parentSitting: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-contact_us"
      );
      if (response.data.status == true) {
        this.parentSitting = response.data.data.settings;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>
