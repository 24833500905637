<template>
  <div>
    <ContactUSAr v-if="$route.params.lang === 'ar'" />
    <ContactUSEn v-else />
  </div>
</template>

<script>
import ContactUSAr from "@/views/ContactUs/ContactUs.vue";
import ContactUSEn from "@/views/ContactUs/ContactUsEn.vue";

export default {
  name: "ContactUsView",
  components: {
    ContactUSAr,
    ContactUSEn,
  },
  watch: {
    "$route.params.lang": {
      immediate: true,
      handler(lang) {
        // Update the <html> lang attribute
        document.querySelector("html").setAttribute("lang", lang);

        // If route's lang parameter doesn't match the current lang, update the route
        if (lang !== this.$route.params.lang) {
          this.$router.push({ params: { lang } });
        }
      },
    },
  },
};
</script>
