<template>
  <div>
    <AboutUsAr v-if="$route.params.lang == 'ar'" />
    <AboutUsEn v-else />
  </div>
</template>

<script>
import AboutUsAr from "@/views/AboutUs/AboutView.vue";
import AboutUsEn from "@/views/AboutUs/AboutViewEn.vue";

export default {
  name: "AboutUsView",
  components: {
    AboutUsAr,
    AboutUsEn,
  },

  watch: {
    "$route.params.lang": {
      immediate: true,
      handler(lang) {
        // Update the <html> lang attribute
        document.querySelector("html").setAttribute("lang", lang);

        // If route's lang parameter doesn't match the current lang, update the route
        if (lang !== this.$route.params.lang) {
          this.$router.push({ params: { lang } });
        }
      },
    },
  },
};
</script>
