<template>
  <div class="aimed">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="arrow">
          <img class="img" :src="parntVission.media" alt="" />
          <img class="arrow-style" src="@/assets/photos/arrow.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />
            {{ parntVission.header }}
          </h4>
          <p>
            {{ parntVission.description }}
          </p>
        </div>
      </div>

      <div
        class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mt-2 mb-2"
        v-if="paentMission"
      >
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />
            {{ paentMission?.header }}
          </h4>
          <p>
            {{ paentMission?.description }}
          </p>
        </div>
      </div>

      <div
        class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mt-2 mb-2"
        v-if="paentMission"
      >
        <div class="arrow">
          <img class="img" :src="paentMission?.media" alt="" />
          <img class="arrow-style3" src="@/assets/photos/arrow.png" alt="" />
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mt-5 mb-2">
        <div
          class="all-img d-flex justify-content-center h-100 align-items-center"
        >
          <img class="img" :src="paentGoals.media" alt="" />
          <img class="arrow-style2" src="@/assets/photos/arrow-2.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 mt-5 mb-2">
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />{{
              paentGoals.header
            }}
          </h4>
          <ol>
            <li
              v-for="(item, i) in paentGoals.description"
              :key="i"
              v-html="item"
            ></li>
          </ol>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />{{
              paentValues.header
            }}
          </h4>
          <ol>
            <li
              v-for="(item, i) in paentValues.description"
              :key="i"
              v-html="item"
            ></li>
          </ol>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="d-flex justify-content-center h-100 align-items-center">
          <img class="img" :src="paentValues.media" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WeAimed",
  props: {
    parntVission: {
      type: Array,
      required: true,
    },
    paentGoals: {
      type: Array,
      required: true,
    },
    paentMission: {
      type: Array,
      required: true,
    },
    paentValues: {
      type: Array,
      required: true,
    },
  },
};
</script>
