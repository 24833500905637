<template>
  <div class="about">
    <h4>
      <router-link to="/">الرئيسية</router-link>
      <i class="fa-solid fa-chevron-left"></i><span>من نحن</span>
    </h4>
    <WhoWe :parntabout="parntabout" />
    <WeAimedVue
      :parntVission="parntVission"
      :paentGoals="paentGoals"
      :paentValues="paentValues"
    />

    <div class="row px-5">
      <div class="col-12">
        <div class="about-us">
          <h3>الهيكل الاداري</h3>
        </div>
        <div class="about-us w-100 d-flex justify-content-center">
          <img src="@/assets/photos/structure_ar.jpeg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WhoWe from "@/components/Global/home/WhoWe.vue";
import WeAimedVue from "@/components/Global/About/WeAimed.vue";
import axios from "axios";
export default {
  name: "AboutView",
  components: {
    WhoWe,
    WeAimedVue,
  },
  data() {
    return {
      parntabout: [],
      parntVission: [],
      paentGoals: [],
      paentValues: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        "https://back.success.sa/public/api/fetch-about"
      );
      if (response.data.status == true) {
        this.parntabout = response.data.data.about_us;
        this.parntVission = response.data.data.vission;
        this.paentGoals = response.data.data.goals;
        this.paentValues = response.data.data.values;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.about {
  h4 {
    margin-top: 11rem;
  }
}
@media screen and (max-width: 600px) {
  .about {
    h4 {
      margin-top: 13rem;
    }
  }
}
@media screen and (max-width: 330px) {
  .about {
    h4 {
      margin-top: 18rem;
    }
  }
}
</style>
