<template>
  <div class="error-page">
    <ErrorPagecompo />
  </div>
</template>
<script>
import ErrorPagecompo from "../components/Global/ErrorPagecompo.vue";
export default {
  name: "ErrorPage",
  components: {
    ErrorPagecompo,
  },

  mounted() {
    this.$router.push(`/ar`);
  },
};
</script>
