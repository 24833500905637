import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Home/index.vue";
import AboutUsView from "../views/AboutUs/index.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsUse from "@/views/TermsUse.vue";
import ErrorPage from "@/views/ErrorPage.vue";
import CommonQuestions from "@/views/CommonQuestions.vue";
import ContactUs from "@/views/ContactUs/index.vue";

const routes = [
  {
    path: "/:lang",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:lang/about",
    name: "about",
    component: AboutUsView,
  },
  {
    path: "/:lang/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/:lang/terms-use",
    name: "terms-use",
    component: TermsUse,
  },
  {
    path: "/:lang/common-questions",
    name: "common-questions",
    component: CommonQuestions,
  },
  {
    path: "/:lang/contact-us",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/:catchAll(.*)",
    name: "ErrorPage",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
