<template>
  <lay-out>
    <router-view />
  </lay-out>
</template>
<script>
import LayOut from "./components/Global/LayOut.vue";

export default {
  components: { LayOut },

};
</script>

<style lang="scss">
@import url("./assets/scss/MainStayle.scss");
@import url("./assets/scss/_responsive.scss");
</style>
