<template>
  <div class="who-we mt-7" :class="{ 'mt-0': $route.name == 'about' }">
    <div class="row d-flex align-items-center">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 who-sec">
        <div class="adress">
          <!-- <h4>
            <img src="@/assets/photos/Group.svg" alt="" />{{
              parntabout.header
            }}
          </h4> -->
          <p class="par">{{ parntabout.description }}</p>
          <!-- <button class="more" @click="$router.push('/about')">
            عرض المزيد <i class="fa-solid fa-circle-left"></i>
          </button> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="img" v-for="(img, i) in parntabout.media" :key="i">
          <img :src="img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhoWe",
  data() {
    return {};
  },
  props: {
    parntabout: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.who-we {
  @media screen and (max-width: 600px) {
    margin-top: 21rem;

    &.mt-0 {
      margin-top: 0 !important;
    }
  }
}

.who-we {
  @media screen and (max-width: 330px) {
    margin-top: 11rem;

    &.mt-0 {
      margin-top: 0 !important;
    }
  }
}
</style>
