<template>
  <div class="layout">
    <TopNave />
    <Navbar />
    <div class="content">
      <slot></slot>
    </div>
    <FooterApp v-if="$route.params.lang == 'ar'" />
    <FooterAppEn v-else />
  </div>
</template>
<script>
import TopNave from "./TopNav.vue";
import Navbar from "./Navbar.vue";
import FooterApp from "./FooterApp.vue";
import FooterAppEn from "./FooterAppEn.vue";

export default {
  components: { Navbar, FooterApp, TopNave, FooterAppEn },
};
</script>
<style scoped lang="scss"></style>
