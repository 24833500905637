<template>
  <div class="serveses">
    <h4>
      <img src="@/assets/photos/Group.svg" alt="" />{{ parntservices.header }}
    </h4>
    <div class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12"
        v-for="(ser, i) in localServiceFeatures"
        :key="i"
      >
        <div class="card-serveses">
          <img :src="ser.icon_link" alt="" />
          <h4>{{ ser.title }}</h4>
          <p class="text-desc" :class="{ expanded: ser.expanded }">
            {{ ser.description }}
          </p>
          <!-- عرض الزر فقط إذا كان النص طويلًا -->
          <button
            v-if="ser.isTextLong"
            @click="toggleDescription(i)"
            class="show-more-btn"
          >
            {{
              ser.expanded
                ? $route.params.lang === "en"
                  ? "Show Less"
                  : "اغلاق"
                : $route.params.lang === "en"
                ? "Show More"
                : "اظهار المزيد"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServesesHome",
  data() {
    return {
      // Create a local copy of the prop
      localServiceFeatures: [],
    };
  },
  props: {
    parntservices: {
      type: Array,
      required: true,
    },
    servicefeatures: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleDescription(index) {
      this.localServiceFeatures[index].expanded =
        !this.localServiceFeatures[index].expanded;
    },

    checkIfTextLong(description) {
      const maxLength = 150;
      return description.length > maxLength;
    },
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    servicefeatures: {
      immediate: true,
      handler(newVal) {
        this.localServiceFeatures = newVal.map((service) => ({
          ...service,
          expanded: false,
          isTextLong: this.checkIfTextLong(service.description),
        }));
      },
    },
  },
};
</script>

<style scoped>
.text-desc {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  transition: all 0.3s ease;
}

.text-desc.expanded {
  -webkit-line-clamp: unset;
  display: block;
}

.show-more-btn {
  background: none;
  border: none;
  color: #e5890c;
  cursor: pointer;
  padding: 0;
  margin-top: 8px;
  font-size: 14px;
}

.show-more-btn:hover {
  text-decoration: underline;
}
</style>
